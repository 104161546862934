export const errMsgLabels = [
    {
        "_id": "67caa175bfc8ba3a69a61822",
        "label": "invalid_access_token",
        "message": "Invalid access token."
    },
    {
        "_id": "67caa175bfc8ba3a69a61823",
        "label": "user_not_verified",
        "message": "User not verified."
    },
    {
        "_id": "67caa175bfc8ba3a69a61824",
        "label": "date_time_not_available",
        "message": "Please select different date or time this slot is already taken"
    },
    {
        "_id": "67caa175bfc8ba3a69a61825",
        "label": "cannot_reset_password",
        "message": "cannot reset password of third party logins"
    },
    {
        "_id": "67caa175bfc8ba3a69a61826",
        "label": "this_vacancy_is_already_booked_or_expired",
        "message": "this vacancy is already booked or expired"
    },
    {
        "_id": "67caa175bfc8ba3a69a61827",
        "label": "this_bid_is_already_accepted",
        "message": "This bid is already accepted"
    },
    {
        "_id": "67caa175bfc8ba3a69a61828",
        "label": "invalid_bid_price",
        "message": "Your bid is lower than the minimum bid required on this vacancy"
    },
    {
        "_id": "67caa175bfc8ba3a69a61829",
        "label": "cannot_bid",
        "message": "The vacancy is a Fixed price vacancy, you cannot bid on it"
    },
    {
        "_id": "67caa175bfc8ba3a69a6182a",
        "label": "vacancy_closed",
        "message": "You cannot bid on this vacancy, this vacancy is closed"
    },
    {
        "_id": "67caa175bfc8ba3a69a6182b",
        "label": "vacancy_not_found",
        "message": "No Vacancy found with this ID"
    },
    {
        "_id": "67caa175bfc8ba3a69a6182c",
        "label": "register_center_first",
        "message": "You have not registered any center, Register any center first"
    },
    {
        "_id": "67caa175bfc8ba3a69a6182d",
        "label": "discounted_price_must_be_less_than_price",
        "message": "discounted price must be less than regular price"
    },
    {
        "_id": "67caa175bfc8ba3a69a6182e",
        "label": "password_updated",
        "message": "Your password has been updated"
    },
    {
        "_id": "67caa175bfc8ba3a69a6182f",
        "label": "user_verified",
        "message": "User successfully verified"
    },
    {
        "_id": "67caa175bfc8ba3a69a61830",
        "label": "incorrect_email",
        "message": "The email you entered is incorrect"
    },
    {
        "_id": "67caa175bfc8ba3a69a61831",
        "label": "center_not_found",
        "message": "No center found for this user"
    },
    {
        "_id": "67caa175bfc8ba3a69a61832",
        "label": "center_already_exists",
        "message": "You have already registered a center, create an account with a different email to register more centers"
    },
    {
        "_id": "67caa175bfc8ba3a69a61833",
        "label": "invalid_center_name",
        "message": "A center with this name and address already exists"
    },
    {
        "_id": "67caa175bfc8ba3a69a61834",
        "label": "invalid_verification_token",
        "message": "Invalid verification token."
    },
    {
        "_id": "67caa175bfc8ba3a69a61835",
        "label": "invalid_refresh_token",
        "message": "Invalid refresh token."
    },
    {
        "_id": "67caa175bfc8ba3a69a61836",
        "label": "is_already_verified",
        "message": "user is already verified"
    },
    {
        "_id": "67caa175bfc8ba3a69a61837",
        "label": "user_not_found",
        "message": "User not found."
    },
    {
        "_id": "67caa175bfc8ba3a69a61838",
        "label": "user_already_exists",
        "message": "User already exists."
    },
    {
        "_id": "67caa175bfc8ba3a69a61839",
        "label": "invalid_email_password",
        "message": "Invalid email or password."
    },
    {
        "_id": "67caa175bfc8ba3a69a6183a",
        "label": "incomplete_profile",
        "message": "Incomplete profile."
    },
    {
        "_id": "67caa175bfc8ba3a69a6183b",
        "label": "invalid_request",
        "message": "Invalid request body."
    },
    {
        "_id": "67caa175bfc8ba3a69a6183c",
        "label": "internal_server_error",
        "message": "Internal Server Error."
    },
    {
        "_id": "67caa175bfc8ba3a69a6183d",
        "label": "invalid_client_grant",
        "message": "Invalid client or grant type."
    },
    {
        "_id": "67caa175bfc8ba3a69a6183e",
        "label": "redirect_uri_not_allowed",
        "message": "Redirect URI not allowed."
    },
    {
        "_id": "67caa175bfc8ba3a69a6183f",
        "label": "route_not_found",
        "message": "Route not found."
    },
    {
        "_id": "67caa175bfc8ba3a69a61840",
        "label": "invalid_code_expired",
        "message": "Invalid Code or expired."
    },
    {
        "_id": "67caa175bfc8ba3a69a61841",
        "label": "user_created",
        "message": "User created successfully."
    },
    {
        "_id": "67caa175bfc8ba3a69a61842",
        "label": "profile_updated",
        "message": "Profile updated successfully."
    },
    {
        "_id": "67caa175bfc8ba3a69a61843",
        "label": "tokens_generated",
        "message": "Tokens generated successfully."
    },
    {
        "_id": "67caa175bfc8ba3a69a61844",
        "label": "role_already-exists",
        "message": "this role already exists for this user"
    },
    {
        "_id": "67caa175bfc8ba3a69a61845",
        "label": "client_code_required",
        "message": "clientId and code are required parameters"
    },
    {
        "_id": "67caa175bfc8ba3a69a61846",
        "label": "admin_protected_route",
        "message": "this route is admin protected"
    }
]