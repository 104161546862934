import { errMsgLabels } from "../constants/labels";
import { store } from "../store/store";

export const getMessage = (errorLabel: string) => {
  // const storedLabels = store?.getState().label;
  const messageToReturn = errMsgLabels?.find(
    (label:any) => label.label === errorLabel
  );
  return messageToReturn?.message;
};
